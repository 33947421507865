import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/milk/productions/categories/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProductCategory(ctx, productCategory) {
      return new Promise((resolve, reject) => {
        axios
          .post('/milk/productions/categories', productCategory)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProductCategory(ctx, productCategory) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/milk/productions/categories/${productCategory.id}`, productCategory)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProductCategory(ctx, category) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/milk/productions/categories/${category.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
