<template>
    <div>
        
        <!-- List produksi susu group by kambing -->
        <b-card no-body class="mb-0 mt-3">
            <!-- Table Top -->
            <b-row class="pt-2 pl-2 pr-2">
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <h4>Eksport Data SmartFarm</h4>
                </b-col>

            </b-row>
            <hr />
            
            <b-row class="pl-2 pr-2 pb-2">
                <b-col
                    xl="12"
                    md="12"
                    sm="12"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >

                <b-form
                class=""
                @submit.prevent="onSubmit()"
                @reset.prevent="resetForm"
                >
                

                <b-form-group
                label="Pilih Data"
                label-for="exportOptions"
                >
                
                <v-select
                v-model="exportOptionSelected.exportOption"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="exportOptions"
                :clearable="false"
                input-id="exportOptions"
              />
            </b-form-group>

            <b-form-group
              label="Mulai Tanggal"
              label-for="start_date"
            >
              <b-form-datepicker
                id="start_date"
                placeholder="Choose a date"
                v-model="params.start_date"
                trim
              />
              
            </b-form-group>

            <b-form-group
              label="Berakhir Tanggal"
              label-for="end_date"
            >
              <b-form-datepicker
                id="end_date"
                placeholder="Choose a date"
                v-model="params.end_date"
                trim
              />

            </b-form-group>

            <div class="d-flex mt-2" >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="isAddButtonClick"
                  @click="!isAddButtonClick"
                  v-if="$can('export', 'data')"
                >
    
                <span v-if="!isAddButtonClick">Unduh</span>
                <b-spinner small v-if="isAddButtonClick" />
                <span v-if="isAddButtonClick">Loading...</span>
                </b-button>
                
              </div>
            </b-form>
            </b-col>
        </b-row>

        </b-card>
    </div>          
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useProductCategoryList from "./useProductCategoryList";
import masterProductStoreModule from "./masterProductStoreModule";

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from 'vue-ripple-directive'

import axios from '@axios'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BFormInput,
        BFormDatepicker,
        BFormGroup,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            exportOptions: [
                {label: 'Data Kambing', value: 'animals'},
                {label: 'Data Perah Susu', value: 'raw-milks'},
                {label: 'Data Produk SmartFarm', value: 'milk-products'}
            ],
        }
    },
    setup() {
        // use Toast
        const toast = useToast()

        const PRODUCT_CATEGORY_STORE_MODULE_NAME = "app-product-category";

        // Register module
        if (!store.hasModule(PRODUCT_CATEGORY_STORE_MODULE_NAME))
            store.registerModule(
                PRODUCT_CATEGORY_STORE_MODULE_NAME,
                masterProductStoreModule
            );

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(PRODUCT_CATEGORY_STORE_MODULE_NAME))
                store.unregisterModule(PRODUCT_CATEGORY_STORE_MODULE_NAME);
        });

        const blankExportData = {
            start_date: null,
            end_date: null,
        }

        const blankExportOptionSelected = {
            exportOption: null,
        }
        
        const params = ref(JSON.parse(JSON.stringify(blankExportData)));

        const exportOptionSelected  = ref(JSON.parse(JSON.stringify(blankExportOptionSelected)));

        const handleDownload = (response, title = "Downloading Data") => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        }

        const isAddButtonClick = ref(false);
        const onSubmit = async () => {
            console.log(exportOptionSelected.value.exportOption.value)
            await axios.get(`exports/${exportOptionSelected.value.exportOption.value}`, {
                params: {
                    start_date: params.value.start_date,
                    end_date: params.value.end_date,
                },
                responseType: 'arraybuffer',
            })
            .then((response) => {
                console.info(response)
                let filename = `Rekap ${exportOptionSelected.value.exportOption.label} Periode ${params.value.start_date} - ${params.value.end_date}`;
                let extension = 'xlsx';
                
                handleDownload(response, `${filename}.${extension}` )
            })
            .catch((err) => console.log('Error:' + err))
        }


        return {
            params,
            exportOptionSelected,
            onSubmit,
            isAddButtonClick,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
